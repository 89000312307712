// Stop devices from sleeping to ensure interaction works
import NoSleep from "nosleep.js";
const noSleep = new NoSleep();

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.APIKEY,
  authDomain: process.env.AUTHDOMAIN,
  databaseURL: process.env.DATABASEURL,
  projectId: process.env.PROJECTID,
  storageBucket: process.env.STORAGEBUCKET,
  messagingSenderId: process.env.MESSIGINGSENDERID,
  appId: process.env.APPID,
};
// Initialize firebaseConfig
firebase.initializeApp(firebaseConfig);
// initialize database
const database = firebase.database();

// get user's data
const username = Date.now();
// Reference the collection created earlier
const fetchMessages = database.ref("messages/");
const fetchUsers = database.ref("users/");
// Get dom element
const informationButton = document.querySelector(".informationButton");
// RGBA color array
const colorArray = [
  "255, 151, 210, 1",
  "231, 255, 82, 1",
  "228, 75, 162, 1",
  "255, 197, 82, 1",
  "44, 193, 118, 1",
  "133, 221, 233, 1",
  "82, 152, 255, 1",
  "137, 127, 246, 1",
  "157, 15, 101, 1",
];
// Data to be sent
let average = 0;
let gradientSetter;
let left = 0;
let userCount = 1;
let activeUsers = 0;

function shuffle(array) {
  let currentIndex = array.length;
  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

function randomIntBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function deviceOrientationHandler() {
  left = event.alpha;
}

function deviceOrientation() {
  if (typeof DeviceOrientationEvent.requestPermission === "function") {
    DeviceOrientationEvent.requestPermission()
      .then((permissionState) => {
        if (permissionState === "granted") {
          window.addEventListener(
            "deviceorientation",
            deviceOrientationHandler,
          );
        }
      })
      .catch(console.error);
  } else {
    // handle regular non iOS 13+ devices
    window.addEventListener("deviceorientation", deviceOrientationHandler);
  }
}

function deviceAudio() {
  navigator.mediaDevices
    .getUserMedia({
      audio: true,
      video: false,
    })
    .then(function (stream) {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

      shuffle(colorArray);
      const color1 = colorArray[0];
      const color2 = colorArray[2];

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;
      microphone.connect(analyser);
      analyser.connect(scriptProcessor);
      scriptProcessor.connect(audioContext.destination);
      scriptProcessor.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        average = (arraySum / array.length) * 100;
        gradientSetter = `linear-gradient(${left}deg, rgba(${color1}) 0%, rgba(${color2}) ${
          100 - average / 100
        }%)`;

        if (window.innerWidth < window.innerHeight) {
          document
            .querySelector("#hashPhone")
            .style.setProperty(
              "--volume",
              (average / window.innerWidth) * 50 + "vw",
            );
        }
        sendMessage();
      };
    })
    .catch(function (err) {
      /* handle the error */
      console.error(err);
    });
}

// fetchMessages.on("child_added", () => {
//   fetchUsers.once("value", (snapshot) => {
//     const userCount = snapshot.val().counter
//       ? snapshot.val().counter.userCount + 1
//       : 1;
//     console.log(userCount);
//     sendUsers(userCount);
//   });
// });

// Send values to database
function sendUsers(userCount) {
  // Create database collection and send in the data
  database.ref("users/counter").set({
    userCount,
  });
}

function clearMessages() {
  // Wipe the messages data by setting it to nothing
  database.ref("messages").set({});
}

// Send values to database
function sendMessage() {
  const minVolume = average > 10 ? average : 5;
  const volume = average;
  const gradient = gradientSetter;

  document.querySelector("#data").innerText = `Left: ${(left * 100) / 360}%
  Width: ${(minVolume / window.innerWidth) * 25 + 5}vw
  Bottom: ${(minVolume / window.innerWidth) * 25 + 5 / 2}vw
  Color: ${gradient}
  Blur: ${(minVolume / window.innerWidth) * 25 + 5 / 8}
  Active Users: ${activeUsers}
  Total Users: ${userCount}`;

  // Create database collection and send in the data
  database.ref("messages/" + username).set({
    username,
    volume,
    gradient,
    left,
  });
}

function removeUser(username) {
  database.ref("messages/" + username).remove();
}

function checkHash() {
  const currentUrl = location.hash;
  if (currentUrl) {
    document.querySelector("body").classList.add("sending-data");
  }
}

function wrapLetters() {
  // Wrap every letter in a span
  const title = document.querySelectorAll(".animateTextItem");
  title.forEach((eachTitle) => {
    eachTitle.innerHTML = eachTitle.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>",
    );
  });
}

function connect(event) {
  // Update button style
  event.target.innerText = "...";
  event.target.classList.add("connecting");
  // Ask for device orientation data
  deviceOrientation();
  // Ask for microphone data
  deviceAudio();
  // Stop multiple clicks
  event.target.disabled = true;
  // Disable phone sleep mode
  noSleep.enable();
  // Update DOM
  event.target.classList.add("connected");
  event.target.innerText = "";

  fetchUsers.once("value", (snapshot) => {
    userCount = snapshot.val().counter
      ? snapshot.val().counter.userCount + 1
      : 1;
    console.log(userCount);
    sendUsers(userCount);
  });
}

// Create QR code image from URL
new QRCode(
  document.getElementById("qrCode"),
  window.location.href + "#" + username,
);
// Add alt text to QR image
document.querySelector("#qrCode img").setAttribute("alt", "QR Code");

checkHash();
wrapLetters();

// Event Listeners

informationButton.addEventListener("click", () => {
  if (
    !document
      .querySelector("#information")
      .classList.contains("showInformation")
  ) {
    document.querySelector("#information").classList.add("showInformation");
  } else {
    document.querySelector("#information").classList.remove("showInformation");
  }
});

// Copy URL in information model
document.querySelector(".copyUrl").addEventListener("click", () => {
  navigator.clipboard.writeText(window.location.href);
  document.querySelector(".copyUrl").innerText = "Copied URL";
});

document.querySelectorAll(".connectButton").forEach((connectButton) => {
  connectButton.addEventListener("click", connect, false);
});

// When Firebase is updated, update CSS variables
fetchMessages.limitToLast(25).on("child_changed", (snapshot) => {
  // console.log("child_changed");
  // console.log("update DOM");

  // Limit the volume
  const minVolume = snapshot.val().volume > 10 ? snapshot.val().volume : 5;

  // Set CSS variables for each user from database
  document
    .querySelector("#messages")
    .style.setProperty(
      "--" + snapshot.val().username + "volume",
      (minVolume / window.innerWidth) * 25 + 5 + "vw",
    );
  document
    .querySelector("#messages")
    .style.setProperty(
      "--" + snapshot.val().username + "color",
      snapshot.val().gradient,
    );

  // If device orientation
  if (snapshot.val().left != 0) {
    document
      .querySelector("#messages")
      .style.setProperty(
        "--" + snapshot.val().username + "left",
        (snapshot.val().left * 100) / 360 + "%",
      );
  } else {
    document
      .querySelector("#messages")
      .style.setProperty("--" + snapshot.val().username + "left", "46%");
  }
});

// Check for new data from Firebase using the onChildAdded event listener
fetchMessages.limitToLast(25).on("child_added", (snapshot) => {
  console.log("child added");
  // Check if user is older than 5 min
  const timer = Date.now() - 1000 * 60 * 5;
  if (snapshot.val().username < timer) {
    console.log("remove user");
    // removeUser(snapshot.val().username);
  } else {
    // console.log("update DOM");
    const message = `<li style="z-index:-${activeUsers}; left: var(--${
      snapshot.val().username
    }left); bottom: calc(var(--${
      snapshot.val().username
    }volume) / 2); filter: blur(calc(var(--${
      snapshot.val().username
    }volume) / 8)); background: var(--${
      snapshot.val().username
    }color); width: var(--${snapshot.val().username}volume); height: var(--${
      snapshot.val().username
    }volume)" class="user${snapshot.val().username}"></li>`;
    // Append the message on the page
    document.getElementById("messages").innerHTML += message;

    activeUsers++;

    const minVolume = snapshot.val().volume > 10 ? snapshot.val().volume : 5;

    // Set CSS variables for each user from database
    document
      .querySelector("#messages")
      .style.setProperty(
        "--" + snapshot.val().username + "volume",
        (minVolume / window.innerWidth) * 25 + 5 + "vw",
      );
    document
      .querySelector("#messages")
      .style.setProperty(
        "--" + snapshot.val().username + "color",
        snapshot.val().gradient,
      );

    // If device orientation
    if (snapshot.val().left != 0) {
      document
        .querySelector("#messages")
        .style.setProperty(
          "--" + snapshot.val().username + "left",
          (snapshot.val().left * 100) / 360 + "%",
        );
    } else {
      document
        .querySelector("#messages")
        .style.setProperty("--" + snapshot.val().username + "left", "46%");
    }

    // console.log(`${(snapshot.val().left * 100) / 360}%`);
  }
});

// Check for new data from Firebase using the value event listener
// fetchMessages.once("value", (snapshot) => {
//   console.log("value once");
//   if (!snapshot.val()) return;

//   const message = `<li style="style="z-index:${activeUsers}; left: var(--${
//     snapshot.val().username
//   }left); bottom: calc(var(--${
//     snapshot.val().username
//   }volume) / 2); filter: blur(calc(var(--${
//     snapshot.val().username
//   }volume) / 8)); background: var(--${
//     snapshot.val().username
//   }color); width: var(--${snapshot.val().username}volume); height: var(--${
//     snapshot.val().username
//   }volume)" class="user${snapshot.val().username}"></li>`;
//   // Append the message on the page
//   document.getElementById("messages").innerHTML += message;
// });

// Timeline animations
// QR Code
anime
  .timeline({})
  .add({
    targets: "#qrCode",
    opacity: [0, 1],
    // easing: "easeOutExpo",
    duration: 16000,
    delay: 2000,
  })
  .add({
    targets: "#qrCode",
    opacity: [1, 0],
    easing: "easeOutExpo",
    duration: 3500,
  });

// Menu link
anime
  .timeline({})
  .add({
    targets: "#information",
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 2000,
    delay: 8000,
  })
  .add(
    {
      targets: "#orbs",
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 2500,
    },
    12500,
  );

// Introduction - desktop
anime
  .timeline({})
  .add({
    targets: "#introduction .animateText li:nth-child(1) .letter",
    translateX: [30, 0],
    translateZ: 0,
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 1500,
    delay: (el, i) => 500 + 30 * i,
  })
  .add({
    targets: "#introduction .animateText li:nth-child(1) .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 1400,
    delay: (el, i) => 100 + 30 * i,
  })
  .add(
    {
      targets: "#introduction .animateText li:nth-child(2) .letter",
      translateX: [30, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 3000,
      delay: (el, i) => 500 + 30 * i,
    },
    5000,
  )
  .add({
    targets: "#introduction .animateText li:nth-child(2) .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 4000,
    delay: (el, i) => 100 + 30 * i,
  })
  .add({
    targets: "#introduction .animateText .helperText .letter",
    translateX: [0, -30],
    opacity: [0, 1],
    easing: "easeInExpo",
    duration: 1800,
    delay: (el, i) => 100 + 30 * i,
  })
  .add({
    targets: "#introduction .animateText .helperText .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 10000,
    delay: (el, i) => 100 + 30 * i,
  });
// Introduction - phone
anime
  .timeline({})
  .add({
    targets: "#introductionPhone .animateText li:nth-child(1) .letter",
    translateX: [30, 0],
    translateZ: 0,
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 1000,
    delay: (el, i) => 500 + 30 * i,
  })
  .add({
    targets: "#introductionPhone .connectButton",
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 1000,
  })
  .add({
    targets: "#introductionPhone .animateText li:nth-child(1) .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 500,
    delay: (el, i) => 100 + 30 * i,
  })
  .add(
    {
      targets: "#introductionPhone .animateText li:nth-child(2) .letter",
      translateX: [30, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1000,
      delay: (el, i) => 500 + 30 * i,
    },
    5000,
  )
  .add({
    targets: "#introductionPhone .animateText li:nth-child(2) .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 1500,
    delay: (el, i) => 100 + 30 * i,
  });

// // Connect button
// anime
//   .timeline({})
//   .add({
//     targets: "#connect .animateText li:nth-child(1) .letter",
//     translateX: [30, 0],
//     translateZ: 0,
//     opacity: [0, 1],
//     easing: "easeOutExpo",
//     duration: 1900,
//     delay: (el, i) => 500 + 30 * i,
//   })
//   .add({
//     targets: "#connect .animateText li:nth-child(1) .letter",
//     translateX: [0, -30],
//     opacity: [1, 0],
//     easing: "easeInExpo",
//     duration: 1800,
//     delay: (el, i) => 100 + 30 * i,
//   })
//   .add(
//     {
//       targets: "#connect .animateText li:nth-child(2) .letter",
//       translateX: [30, 0],
//       translateZ: 0,
//       opacity: [0, 1],
//       easing: "easeOutExpo",
//       duration: 3000,
//       delay: (el, i) => 500 + 30 * i,
//     },
//     3000,
//   )
//   .add({
//     targets: "#connect .animateText li:nth-child(2) .letter",
//     translateX: [0, -30],
//     opacity: [1, 0],
//     easing: "easeInExpo",
//     duration: 1800,
//     delay: (el, i) => 100 + 30 * i,
//   });

// Introduction - has # in url
anime
  .timeline({})
  .add({
    targets: "#hashPhone .animateText li:nth-child(1) .letter",
    translateX: [30, 0],
    translateZ: 0,
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 1900,
    delay: (el, i) => 500 + 30 * i,
  })
  .add({
    targets: "#hashPhone .animateText li:nth-child(1) .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 1800,
    delay: (el, i) => 100 + 30 * i,
  })
  .add(
    {
      targets: "#hashPhone .animateText li:nth-child(2) .letter",
      translateX: [30, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 3000,
      delay: (el, i) => 500 + 30 * i,
    },
    3000,
  )
  .add({
    targets: "#hashPhone .animateText li:nth-child(2) .letter",
    translateX: [0, -30],
    opacity: [1, 0],
    easing: "easeInExpo",
    duration: 1800,
    delay: (el, i) => 100 + 30 * i,
  });
